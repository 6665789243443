'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('index.order-details', {
                url: '/order-details',
                templateUrl: 'app/order-details/order.details.html',
                controller: 'OrderDetailCtrl'
            })

    }]);